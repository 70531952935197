import usePopups from "hooks/usePopups";
import bell from "assets/images/newSubscription/bell.svg";

export default ({ userProfile, className = "", text = "" }) => {
	const { showSubscriptionPopup } = usePopups();

	return (
		<div className={`blue-box-subscription ${className}`} onClick={showSubscriptionPopup}>
			<div class="right">
				<span class="text">{text}</span>
				<button onClick={showSubscriptionPopup} class="btn2">
					{userProfile?.gender ? "שדרגי" : "שדרג"} למנוי שליש+
				</button>
			</div>
			<img src={bell} alt="Notification" />
		</div>
	);
};
