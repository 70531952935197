import i18next from "i18next";

import currentLanguage from "helpers/currentLanguage";
import { Link } from "react-router-dom";
import Collapse from "components/Collapse";
import usePopups from "hooks/usePopups";
import isMobile from "helpers/isMobile";

export default ({ plan = {} }) => {
	const { closePopup } = usePopups();
	const subTitle = plan?.long_description?.sub_title?.[currentLanguage()];

	const openInNewTab = (e) => {
		e.preventDefault();
		window.open("/terms", "_blank", "top=100 toolbar=0,location=0,menubar=0,width=600,height=600");
	};
	return (
		<div className="conditions">
			<h6 style={{ fontWeight: "600", marginBottom: 0 }}>
				{plan?.long_description?.title?.[currentLanguage()]} <br />
			</h6>

			<span style={{ marginTop: "1px" }}>
				{i18next.t("Subscription.Footer.General")}
				<Link
					to={{ pathname: "/account/terms", state: { isFromSubscriptionModal: true } }}
					onClick={!isMobile() ? openInNewTab : closePopup}>
					{i18next.t("Subscription.Footer.Terms")}
				</Link>
			</span>
			{
				// <div className="morePay">
				// 	<Collapse title={"אמצעי תשלום נוספים"} collapsHeight={0}>
				// 		<div className="morePayContent">
				// 			<h6>
				// 				{/* <b>תשלום באמצעות חיוב חשבון הסלולר </b>- אין לכם כרטיס אשראי? חדש! רכישת מנוי באמצעות
				// 			חיוב הטלפון הנייד! התשלום מבוצע בחיוב הנייד ממנו בוצעה השיחה בתשלום חד פעמי ולא מתחדש.
				// 			למעבר לרכישה לחצו:<a href="#"> יום אחד ב 50₪ </a>
				// 			<br /> */}
				// 				<b>הפקדה / העברה בנקאית </b> - לחשבון הבנק של "סמארט להכיר חכם" לבנק הפועלים, סניף
				// 				636 מס' חשבון 44444. יש לשלוח את אישור ההפקדה/העברה בנקאית עם פרטי המייל למייל:
				// 				info@jsamrt.app. תשלום בטלפון באמצעות כרטיס אשראי - חייגו 08-6223925
				// 				<br />
				// 			</h6>
				// 		</div>
				// 	</Collapse>
				// </div>
			}
		</div>
	);
};
