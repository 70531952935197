import Masonry from "react-masonry-css";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import SearchCard from "components/search/card/SearchCard";
import AnimatedBox from "components/AnimatedBox";
import { getSearchFreeTextFilter, getStatus } from "redux/search/selectors";
import { search } from "redux/search/actions";
import ApiRequestStatus from "consts/apiRequestStatus";
import { ReactComponent as HeartImage } from "assets/images/heart_2.svg";
import { useState, useEffect } from "react";
import _ from "lodash";
import isMobile from "helpers/isMobile";
import blogService from "services/blogService";
import getArticlesFilterByUser from "helpers/getArticlesFilterByUser";
import { getUserProfile } from "redux/profile/selectors";
import useShowJoinNowBtn from "hooks/useShowJoinNowBtn";
import { getCreateDateTime } from "redux/general/selectors";
import { getEffortButtonTitle } from "redux/system/selectors";
import templateMsgService from "services/templateMsg.service";
import useNavigator from "hooks/useNavigator";

export default ({ users = [], ...rest }) => {
	const dispatch = useDispatch();
	const [articles, setArticles] = useState([]);
	const [isMobileDevice] = useState(isMobile());
	const [displayUsers, setDisplayUsers] = useState(users);
	const [templates, setTemplates] = useState([]);

	const searchStatus = useSelector(getStatus);
	const userProfile = useSelector(getUserProfile);
	const createDateTime = useSelector(getCreateDateTime);
	const isUserWithoutSubscription = useShowJoinNowBtn();
	const history = useHistory();
	const currentPath = history.location.pathname;
	const effortBtn = useSelector(getEffortButtonTitle);

	const isEmptyPage = displayUsers.length === 0;
	const isEmptySearch = () =>
		isEmptyPage && currentPath === "/search" && searchStatus === ApiRequestStatus.SUCCESSFULLY;

	useEffect(() => {
		if (!users?.length) return setDisplayUsers([]);
		setDisplayUsers(users);
	}, [users]);

	useEffect(() => {
		blogService.getBlogsByUser().then((data) => {
			if (!data) return;
			const listArticles = getArticlesFilterByUser(
				userProfile,
				Object.values(data || {}),
				!isUserWithoutSubscription,
				createDateTime
			);
			setArticles(listArticles);
		});
	}, []);

	useEffect(() => {
		templateMsgService
			.fetchTemplateMsg()
			.then((data) => {
				if (!!data && !_.isEmpty(data)) {
					const newTemplates = Object.values(data).filter((item) => {
						if (!item.active) return false;
						return true;
					});
					setTemplates(newTemplates);
				}
			})
			.catch((e) => console.log(e));
	}, []);

	return (
		<>
			{displayUsers.length > 0 ? (
				<Masonry
					breakpointCols={{
						default: 4,
						1300: 3,
						1000: 1,
					}}
					className="wrapAllResults my-masonry-grid"
					columnClassName="my-masonry-grid_column_oneCol">
					{displayUsers.map((user, index) => {
						if (isMobileDevice) {
							// Check if the current index is even and there are at least two users left
							if (index % 2 === 0 && index + 1 < displayUsers.length) {
								const user1 = displayUsers[index];
								const user2 = displayUsers[index + 1];

								return (
									<div
										style={{ display: "flex", gap: "18px", flexWrap: "wrap" }}
										key={`zzz-${user1.uid}-${user2.uid}`}>
										<AnimatedBoxContainer
											key={user1.uid}
											effortBtn={effortBtn}
											templates={templates}
											index={index}
											user={user1}
											{...rest}
										/>
										<AnimatedBoxContainer
											key={user2.uid}
											effortBtn={effortBtn}
											templates={templates}
											index={index + 1}
											user={user2}
											{...rest}
										/>

										{!!articles.length &&
											!!articles[index / 10 - 1] &&
											!!index &&
											index % 10 == 0 && <BlogBox blog={articles[index / 10 - 1]}></BlogBox>}
									</div>
								);
							}
							// Handle the case when there's only one user left
							if (index === displayUsers.length - 1 && displayUsers.length % 2 !== 0) {
								return (
									<AnimatedBoxContainer
										key={`zzz-${user.uid}`}
										effortBtn={effortBtn}
										templates={templates}
										index={index}
										user={user}
										{...rest}
									/>
								);
							}
						} else {
							return (
								<AnimatedBoxContainer
									key={user.uid}
									effortBtn={effortBtn}
									templates={templates}
									index={index}
									user={user}
									{...rest}
								/>
							);
						}
					})}
				</Masonry>
			) : isEmptySearch() ? (
				<EmptySearch />
			) : null}
		</>
	);
};

const AnimatedBoxContainer = ({ user, index, ...rest }) => {
	return <SearchCard key={user.uid || `search-${index}`} user={user} {...rest} />;
};

const EmptySearch = () => {
	const freeText = useSelector(getSearchFreeTextFilter);
	const dispatch = useDispatch();
	const onEmptySearch = () => {
		dispatch(search(""));
	};
	return (
		<div className="emptySearch">
			<div>
				{i18next.t("Search.No Results", { search: freeText })} {", "}
				<Link onClick={onEmptySearch}>{i18next.t("Search.Try Another Search")}</Link>
			</div>
		</div>
	);
};

const BlogBox = ({ blog }) => {
	const { navigateToPath } = useNavigator();
	const slug = blog?.slug;

	return (
		<div
			onClick={() => navigateToPath(`/blog/${slug}`)}
			className="blogBox"
			style={{
				width: "98%",
				height: "150px",
				marginBottom: "16px",
				marginTop: "-16px",
				borderRadius: "16px",
				display: "flex",
				boxShadow: "0px 0px 7px 0px rgba(0, 0, 0, 0.10)",
			}}>
			<img
				style={{ width: "143px", borderRadius: "0 16px 16px 0", objectFit: "cover" }}
				src={blog.mainImage}
			/>
			<div style={{ padding: "20px" }}>
				<h3
					style={{
						fontWeight: "bold",
						fontSize: "15px",
						height: "36px",
						overflow: "hidden",
						color: "#000",
						lineHeight: "1.1",
					}}>
					{blog.title}
				</h3>
				<div
					style={{
						color: "#000",
						textAlign: "right",
						fontSize: "14px",
						fontStyle: "normal",
						fontWeight: "400",
						lineHeight: "normal",
						height: "50px",
						overflow: "hidden",
					}}>
					{blog?.description || blog.title}
				</div>
				<div
					className="blogButton"
					style={{
						color: "#0BC1C7",
						leadingTrim: "both",
						textEdge: "cap",
						fntFamily: "Assitant",
						fontSize: "15px",
						fntStyle: "normal",
						fontWeight: "700",
						lneHeight: "normal",
					}}>
					{"לכתבה המלאה"}
				</div>
			</div>
		</div>
	);
};
