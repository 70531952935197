import i18next from "i18next";
import redtag from "assets/images/icons/redtag.svg";

export const DBPlanItem = ({
	selectedPlanKey,
	pkey,
	mostProfitable,
	sub_long,
	sub_time,
	monthly_price,
	onPress,
	usersSiteText = null,
}) => {
	return (
		<div
			className={`onePlan${pkey === selectedPlanKey ? " activePlan " : ""}${
				mostProfitable ? " wrapLaunchPlan " : ""
			}`}
			onClick={() => onPress(pkey)}>
			{mostProfitable && (
				<div className="launchPrice">
					<span>{i18next.t("Subscription.Recommended")}</span>
				</div>
			)}
			{!!usersSiteText ? (
				<div className="nameOfMon" style={{ marginTop: "10px" }}>
					{usersSiteText}
				</div>
			) : (
				<>
					<div className="numOfMon" style={{ marginTop: "20px" }}>
						{sub_long}
					</div>
					<div className="nameOfMon">{i18next.t(`Subscription.Times.${sub_time}`)}</div>
				</>
			)}
			<div className="monthPrice">
				₪{monthly_price}
				<div className="period">לחודש</div>
			</div>
		</div>
	);
};
