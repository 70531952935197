import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { Link } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import { getIsManualyPromotedUser, getPromotionsList } from "redux/promotions/selectors";
import { openPromotion } from "redux/promotions/actions";
import { activeUserId } from "redux/chat/actions";
import { updatePromotionsRead } from "redux/chatMeta/actions";
import PromotionStatus from "consts/promotionStatus";
import ApiRequestStatus from "consts/apiRequestStatus";
import { TransformImg } from "helpers/cloudinary";
import { Face, Fill } from "consts/cloudinaryOptions";
import { isUserOnline } from "redux/presence/selectors";
import ScreensSizes from "consts/screensSizes";
import usePopups from "hooks/usePopups";
import isMobile from "helpers/isMobile";
import { getChat } from "redux/chatReceived/selectors";
import useNavigator from "hooks/useNavigator";
import privatePictureRound from "assets/images/emptyImages/privateRound.png";
import placeholderManRound from "assets/images/emptyImages/placeholderManRound.png";
import placeholderWomanRound from "assets/images/emptyImages/placeholderWomanRound.png";
import QueueService from "services/queue";
import QueueEvents from "consts/baseChatEvents";
import PromotionService from "services/promotion";
import { getUserProfile } from "redux/profile/selectors";
import promotion from "services/promotion";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { getUserGeneral } from "redux/general/selectors";
import promote from "assets/images/newSubscription/promote.svg";

const Item = ({ item, onPress }) => {
	const chatID = useSelector(getChat(item.uid));
	const isLoaded = item && item.status === ApiRequestStatus.SUCCESSFULLY;
	const uri = isLoaded ? item.pictures?.[item.mainPictureName]?.url || "" : "";
	const path = TransformImg(uri, undefined, null, Fill.Thumb, Face.Face, "r_max");
	const isManualyPromotedUser = useSelector(getIsManualyPromotedUser(item.uid));
	const isUnView = item.unView || item.promotionStatus === PromotionStatus.Promoted;
	const isOnline = useSelector(isUserOnline(item.uid));
	const userStatus = isOnline ? "online" : "offline";
	const active_userId = useSelector((state) => state.chat.active_userId);
	const isMobile = window.innerWidth <= ScreensSizes.MobileWidth;
	const isActiveUser = !isMobile && active_userId === item.uid;
	const defaultImage = item.gender ? placeholderWomanRound : placeholderManRound;
	return (
		<div
			className={isActiveUser ? " current" : ""}
			onClick={() => onPress(item.uid, isUnView, isManualyPromotedUser, item, chatID)}>
			<Link to="#" className="user-status-box">
				<div className={"avatar-xs mx-auto d-block chat-user-img " + userStatus}>
					<img
						src={uri ? path : item.isPicturesPrivate ? privatePictureRound : defaultImage}
						alt="user-img"
						className="img-fluid rounded-circle avatar-xs"
					/>
					<span className="user-status"></span>
				</div>

				<h5 className="font-size-13 text-truncate mt-3 mb-1">{item.name}</h5>
			</Link>
		</div>
	);
};

export default () => {
	const dispatch = useDispatch();
	const promotions = useSelector(getPromotionsList);
	const navigator = useNavigator();
	const currentUser = useSelector(getUserProfile) || {};
	const { uid, gender } = useSelector(getUserProfile);
	const { showUserProfileSideBar, showUserCardPopup } = usePopups();
	const [promotionSalePlan, setPromotionSalePlan] = useState(null);
	const [promotionWithSalePlan, setPromotionWithSalePlan] = useState([]);
	const userGeneral = useSelector(getUserGeneral);

	const handleOnPress = async (chatUid, unView, isManualyPromotedUser, user, chatID) => {
		if (unView) {
			await QueueService.sendEvent(currentUser.uid, QueueEvents.OpenPromotion, chatUid);
		}
		//	dispatch(openPromotion(chatUid, unView));
		if (isManualyPromotedUser) {
			dispatch(activeUserId(chatUid, chatID));
			navigator.navigateToMassagesReceived();
			!isMobile() && showUserProfileSideBar(user);
		} else {
			!isMobile() ? showUserCardPopup(user, true) : showUserProfileSideBar(user);
		}
	};

	const shouldAddButtons = () => {
		return !isMobile() && promotionWithSalePlan.length > 4;
	};

	useEffect(() => {
		dispatch(updatePromotionsRead());
	}, []);

	useEffect(async () => {
		if (!uid) return;
		const promotionResult = await promotion.getActivePromotionsSalePlan();
		if (!!promotionResult) setPromotionSalePlan(promotionResult);
	}, [uid]);
	useEffect(async () => {
		const promotionWithSaleList = [];
		if (promotionSalePlan && !userGeneral?.isPromoteSale) {
			promotionWithSaleList.push(
				<ItemGetPromotion promotionSalePlan={promotionSalePlan} gender={gender} />
			);
		}
		if (promotions?.length)
			promotions
				.sort((a, b) => b?.timeStamp - a?.timeStamp)
				.map((promotion) => {
					promotionWithSaleList.push(
						<Item key={promotion.uid} item={promotion} onPress={handleOnPress} />
					);
				});

		setPromotionWithSalePlan(promotionWithSaleList);
	}, [promotions, promotionSalePlan]);

	return (
		<div
			className={`px-4 pb-4 recommendedUsers ${shouldAddButtons() ? "" : "dot_remove"}`}
			dir="ltr">
			<AliceCarousel
				responsive={{
					0: { items: 4 },
					1024: { items: 4 },
				}}
				disableDotsControls={shouldAddButtons() ? true : false}
				mouseTracking>
				{promotionWithSalePlan}
			</AliceCarousel>
			{/* {promotions.length === 0 && <p className="message">{i18next.t("No Promotions")}</p>} */}
		</div>
	);
};

const ItemGetPromotion = ({ promotionSalePlan, gender = false }) => {
	const [canBuyPromotion, setCanBuyPromotion] = useState(false);
	const { showPromotionsSalePopup, showFittedMessagePopup } = usePopups();
	const handleClick = () => {
		if (!canBuyPromotion) {
			const text = (
				<div>
					<div>{`אופס. הכל לטובה..:)`}</div>
					<div>{`מכסת הקידומים נסגרה להיום.`}</div>
					<div>{`מבטיחים להגדיל את הכמות בהקדם ובהדרגה.`}</div>
					<div>{`${gender ? "נסי" : "נסה"} שנית מחר`}</div>
					<div>{`בהצלחה ❤️`}</div>
				</div>
			);

			showFittedMessagePopup(text, false);
		} else {
			showPromotionsSalePopup(promotionSalePlan);
		}
	};

	useEffect(async () => {
		if (!!promotionSalePlan?.countPromotionSalePerDay) {
			const canBuyRes = await promotion.getCanBuyPromotion(promotionSalePlan?.key);
			if (canBuyRes?.data?.success) {
				setCanBuyPromotion(!!canBuyRes?.data?.data);
			} else {
				setCanBuyPromotion(false);
			}
		}
	}, []);

	return (
		<div className={""} onClick={handleClick}>
			<Link to="#" className="user-status-box">
				<div className={"avatar-xs mx-auto d-block chat-user-img  "}>
					<img
						className="rotate"
						src={promote}
						style={{ width: "48px", position: "absolute", top: "-5px", right: "-7px" }}
					/>
				</div>

				<h5 className="font-size-13 text-truncate mt-3 mb-1" style={{ fontWeight: "bold" }}>
					{"קידום אישי"}
				</h5>
			</Link>
		</div>
	);
};
