import i18next from "i18next";
import { Nav } from "reactstrap";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import profileImg from "assets/images/icons/userListB.svg";
import settingsImg from "assets/images/icons/settingsB.svg";
import NavLinkItem from "components/NavLinkItem";
import SideBarSubLinkItem from "./SideBarSubLinkItem";
import { getUserSubscriptionType } from "redux/subscription/selectors";
import Subscription from "consts/subscription";
import { getBlockedUsers } from "redux/blocks/selectors";
import { Box } from "@mui/material";
import newVideoDating from "assets/images/speedDate/newVideoDating.png";
import { getIsTester, getUserProfile } from "redux/profile/selectors";
import { useEffect, useState } from "react";
import systemService from "services/system";
import returnIcon from "assets/images/newLogin/return.svg";
import { getPlatform } from "redux/init/selectors";

import bell from "assets/images/newSubscription/bell.svg";
import useShowJoinNowBtn from "hooks/useShowJoinNowBtn";
import usePopups from "hooks/usePopups";
import BuySubscription from "../BuySubscription";
import PromotionsBox from "components/chatSideBar/PromotionsBox";

export default () => {
	const history = useHistory();

	const currentPath = history.location.pathname;
	const subscriptionType = useSelector(getUserSubscriptionType);
	const [showOldSite, setShowOldSite] = useState(false);

	const userProfile = useSelector(getUserProfile);
	const isTester = useSelector(getIsTester);
	const [userTestPermission, setUserTestPermission] = useState(false);
	const platform = useSelector(getPlatform);
	const showJoinNowBtn = useShowJoinNowBtn();
	const handleOldSiteClick = () => {
		window.open("https://www.date4dos.co.il/?login_act=phone", "_blank", "noopener,noreferrer");
	};

	useEffect(() => {
		if (platform === "web" || platform === "mobileWeb") setShowOldSite(true);
	}, [platform]);
	useEffect(() => {
		const fetchTestSiteStatus = async () => {
			const testSite = await systemService.fetchSiteInTest();
			let havePermission = false;
			if ((testSite && isTester) || !testSite) {
				havePermission = true;
			}
			setUserTestPermission(havePermission);
		};

		fetchTestSiteStatus();
	}, []);

	const _sideBarItems = [
		{
			content: i18next.t("Pages.Edit Profile"),
			className: "profileIcon",
			to: "/account/editProfile",
		},
		{
			content: i18next.t("Pages.Edit Pictures"),
			className: "imagesIcon",
			to: "/account/pictures",
		},
		{
			content: i18next.t("Pages.How I Look"),
			className: "profileIcon",
			to: "#",
			openMyProfile: true,
		},
		!!userTestPermission && {
			content: (
				<Box style={{ position: "relative", right: " -2.6rem" }}>
					<img src={newVideoDating} alt="new video dating" />
				</Box>
			),
			className: "",
			to: "/account/videoDate",
		},
		{
			content: i18next.t("Pages.System Settings"),
			icon: settingsImg,
			items: [
				{
					content: i18next.t("Pages.Account Settings"),
					className: "pl-4",
					to: "/account/settings",
				},
				...(subscriptionType === Subscription.PAID
					? [
							{
								content: i18next.t("Pages.Account Subscription"),
								className: "pl-4",
								to: "/account/subscription",
							},
					  ]
					: []),
				{
					content: i18next.t("Pages.Blocked Profiles"),
					className: "pl-4",
					to: "/account/blocked",
				},
				{
					content: i18next.t("Pages.Additional Settings"),
					className: "pl-4",
					to: "/account/more-settings",
				},
			],
		},
		{
			content: i18next.t("Pages.Contact"),
			className: "contactIcon",
			to: "/account/contact",
		},
		{
			content: i18next.t("Pages.Terms"),
			className: "takanonIcon",
			to: "/account/terms",
		},
		{
			content: i18next.t("Pages.Sign Out"),
			className: "signOutIcon",
			isLogout: true,
			to: "/logout",
		},
	].filter((item) => !!item);

	if (!!userProfile?.isTester) {
		_sideBarItems.push({
			content: (
				<Box
					style={{
						position: "relative",
						right: " -2.6rem",
						background: "#ededff",
						borderRadius: "5px",
						padding: "10px",
					}}>
					{i18next.t("משתמשי טסטים")}
				</Box>
			),

			className: "pl-4",
			to: "/account/testUsers",
		});
	}

	return (
		<div className="side-menu ">
			{showJoinNowBtn && (
				<BuySubscription
					text={`רוצה להגדיל את הסיכוי למצוא את החצי השני ?`}
					userProfile={userProfile}
				/>
			)}
			{!showJoinNowBtn && (
				<div style={{ width: "100%" }}>
					<PromotionsBox />
				</div>
			)}

			<Nav vertical className="list-unstyled pb-3">
				{_sideBarItems.map((item, index) => {
					if (item.to) {
						return (
							<NavLinkItem key={index} currentPath={currentPath} {...item}>
								{item.content}
							</NavLinkItem>
						);
					}
					if (item.d4d) {
						return <NavLinkItem>{item.content}</NavLinkItem>;
					}
					return <SideBarSubLinkItem key={index} currentPath={currentPath} {...item} />;
				})}
			</Nav>
			{!!showOldSite && (
				<div style={{ paddingInlineStart: "calc(21% + 45px - 0.55rem", marginBottom: "50px" }}>
					<Box style={{ position: "relative", right: " -2.6rem" }}>
						<div className="blue-box-sidebar" onClick={handleOldSiteClick}>
							<img src={returnIcon} />
							להתחברות לפלטפורמה הישנה
						</div>
					</Box>
				</div>
			)}
		</div>
	);
};
