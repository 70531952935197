import { useEffect, useState } from "react";
import { Spinner, Container, Row, Col } from "reactstrap";

export default ({ className = "" }) => {
	const [show, setShow] = useState(0);
	useEffect(() => {
		let timer;
		if (show < 3) {
			timer = setTimeout(() => {
				setShow(show + 1);
			}, 180);
		}

		return () => {
			if (timer) clearTimeout(timer);
		};
	}, [show]);
	return (
		<Container className={`alignLoader ${className}`}>
			<Row className="justify-content-center align-items-center loaderPosition">
				<Col xs={1}>
					{show > 0 && <Spinner children="" size="sm" type="grow" color="primary" />}
				</Col>
				<Col xs={1}>
					{show > 1 && <Spinner children="" size="sm" type="grow" color="secondary" />}
				</Col>
				<Col xs={1}>
					{show > 2 && <Spinner children="" size="sm" type="grow" color="primary" />}
				</Col>
			</Row>
		</Container>
	);
};
