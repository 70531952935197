import i18next from "i18next";
import { useSelector } from "react-redux";

import { getUserProfile } from "redux/profile/selectors";
import JoinNowButton from "components/JoinNowButton";
import useShowJoinNowBtn from "hooks/useShowJoinNowBtn";
import isMobile from "helpers/isMobile";
import BottomStrip from "components/BottomStrip";

export default () => {
	const loggedInUserProfile = useSelector(getUserProfile);
	const showJoinNowBtn = useShowJoinNowBtn();

	return (
		<>
			{!isMobile() ? (
				<div className="joinUs">
					{showJoinNowBtn && (
						<>
							<span>1{i18next.t(`Pages.Purchase Subscription_${loggedInUserProfile.gender}`)}</span>
							<JoinNowButton text={i18next.t(`Pages.Join Now_${loggedInUserProfile.gender}`)} />
						</>
					)}
				</div>
			) : (
				<BottomStrip />
			)}
		</>
	);
};
