import i18next from "i18next";
import { Button, CardBody, CardText } from "reactstrap";

import Subtitle from "components/Subtitle";
import usePopups from "hooks/usePopups";
import Collapse from "components/Collapse";
import isMobile from "helpers/isMobile";

export default ({ user }) => {
	const { showUserCardPopup, showUserProfileSideBar } = usePopups();
	const isAboutMe = user.aboutMe?.trim() ? true : false;
	const isAboutSpouse = user.aboutSpouse?.trim() ? true : false;

	const showUserDetails = () => {
		!isMobile() ? showUserCardPopup(user) : showUserProfileSideBar(user);
	};

	return (
		<CardBody className="cardBody" onClick={showUserDetails}>
			{isAboutMe && <AboutMe user={user} />}
			<Tags user={user} />
			{user.oneLiner && <LifeSentence user={user} />}
			{isAboutSpouse && <AboutSpouse user={user} />}
			{isAboutMe && isAboutSpouse && <hr />}
			<Button outline className="searchBtn ">
				{i18next.t("Search Card.More About", { name: user.name })}
				<span className="mdi-chevron-down"></span>
			</Button>
		</CardBody>
	);
};

const AboutMe = ({ user }) => {
	return (
		<CardText>
			<Subtitle>{i18next.t("About Me")}</Subtitle>
			<span className="plainTxtCard">{user.aboutMe}</span>
		</CardText>
	);
};

const LifeSentence = ({ user }) => {
	return <span className="lifeSentence">{user.oneLiner}</span>;
};

const AboutSpouse = ({ user }) => {
	return (
		<CardText>
			<Subtitle>{i18next.t(`About Spouse_${user.gender}`)}</Subtitle>
			<span className="plainTxtCard">{user.aboutSpouse} </span>
		</CardText>
	);
};

const Tags = ({ user }) => {
	return (
		<Collapse collapsHeight={63}>
			<div className="tagsInline">
				{Object.values(user.tags || {}).map((value) => (
					<Button size="sm" className="tagsBtn" color="">
						{value}
					</Button>
				))}
			</div>
		</Collapse>
	);
};
