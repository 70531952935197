import "assets/scss/popups/userProfileSidebar.scss";
import SimpleBar from "simplebar-react";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import _ from "lodash";
import { ModalBody, Modal } from "reactstrap";
import MainPicture from "./MainPicture";
import FooterBtnsActions from "./FooterBtnsActions";
import UserProfileDetails from "components/UserProfileDetails";
import ImagesGallery from "./ImagesGallery";
import ShareContacts from "./ShareContacts";
import UserBlocks from "components/blocks";
import UserAboutAndTags from "components/UserAboutAndTags";
import usePopups from "hooks/usePopups";
import { getPopups } from "redux/popups/selectors";
import isMobile from "helpers/isMobile";
import UserDistance from "../UserDistance";
import { isUserOnline } from "redux/presence/selectors";
import UserStatus from "../UserStatus";
import BasicDetails from "./BasicDetails";
import ShareButton from "./ShareButton";
import VipRibbon from "../VipRibbon1";
import ImageTapIndicator from "./ImageTapIndicator";
import { viewUser } from "redux/search/actions";
import placeholderMan from "assets/images/emptyImages/placeholderMan.png";
import placeholderWoman from "assets/images/emptyImages/placeholderWoman.png";
import privatePicture from "assets/images/emptyImages/private.jpg";
import Subscription from "consts/subscription";
import birthday from "assets/images/birthday/happyBirthDay.png";
import getIfUserBirtdayInThisWeek from "helpers/getIfUserBirtdayInThisWeek";
import { getUserProfile } from "redux/profile/selectors";
import UserYoutubeLink from "components/UserYoutubeLink";
export default function UserProfileSidebarModal() {
	const [displayUser, setDisplayUser] = useState(null);
	const { currentUserProfile } = useSelector(getPopups);
	const currentUser = useSelector(getUserProfile) || {};

	const { _closePopupUnderPopup } = usePopups();
	const [imageIndex, setImageIndex] = useState();
	const { showUserCardPopup } = usePopups();
	const dispatch = useDispatch();
	const defaultImage = displayUser?.gender ? placeholderWoman : placeholderMan;
	const isCollageSubscribed =
		displayUser?.isSubscribed ||
		(displayUser?.subscription &&
			(displayUser?.subscription === Subscription.PAID ||
				displayUser?.subscription === Subscription.FREE));

	const pictures =
		_.isObject(displayUser?.pictures) && !_.isEmpty(displayUser?.pictures)
			? Object.keys(displayUser?.pictures || {})?.map((picName) => ({
					src: displayUser?.pictures[picName].url,
			  }))
			: [{ src: displayUser?.isPicturesPrivate ? privatePicture : defaultImage }];

	useEffect(() => {
		if (_.isObject(displayUser?.pictures) && !_.isEmpty(displayUser?.pictures)) {
			setImageIndex(
				Object.keys(displayUser?.pictures || {}).findIndex(
					(picName) => picName === displayUser?.mainPictureName
				)
			);
		} else {
			setImageIndex(0);
		}
	}, [displayUser]);

	useEffect(() => {
		if (!!currentUserProfile) {
			console.log(currentUserProfile);
			setDisplayUser(currentUserProfile);
		}
	}, [currentUserProfile]);

	const isOnline = useSelector(isUserOnline(displayUser?.uid));

	const onTap = () => {
		setImageIndex((i) => (i < pictures.length - 1 ? i + 1 : 0));
	};
	const onTapDot = (i) => {
		setImageIndex(i);
	};

	const showUserDetails = (e) => {
		const exclude = [
			"shareButton",
			"jsmartProfileImg",
			"btn-rounded",
			"reportUsersImage",
			"mdi-chevron-up",
			"mdi-chevron-down",
		];
		const targetClassList = e.target.classList.value;
		if (exclude.findIndex((item) => targetClassList.indexOf(item) !== -1) !== -1) return;
		dispatch(viewUser(displayUser?.uid));
		!isMobile() && showUserCardPopup(displayUser, true);
	};

	const handleEnter = () => {
		document.body.style.overflow = "hidden";
	};
	const handleLeave = () => {
		document.body.style.overflow = "initial";
	};

	const goBack = () => {
		_closePopupUnderPopup();
	};

	useEffect(() => {
		handleEnter();
		return () => {
			handleLeave();
		};
	}, []);

	useEffect(() => {
		const stateObject = { popupOpened: true };
		const title = "Popup Opened";
		const newUrl = window.location.href;
		window.history.pushState(stateObject, title, newUrl);
	}, []);

	return (
		<Modal
			isOpen={true}
			toggle={goBack}
			className="simpleDialog user-profile-sidebar  user-profile-sidebarOpen UserProfileSidebarModal">
			<SimpleBar style={{ maxHeight: "100%" }} className="p-4 user-profile-desc">
				<div className="wrapMainPictureWithMainDetails" onClick={showUserDetails}>
					<Link to="#" onClick={goBack} style={{ zIndex: 20 }}>
						<i onClick={goBack} className="ri-arrow-left-s-line"></i>
					</Link>
					{!!getIfUserBirtdayInThisWeek(displayUser?.dob) && (
						<div
							style={{
								position: "absolute",
								left: "10px",
								top: "10px",
								zIndex: 1,
							}}>
							<img src={birthday} alt="birthday" />
						</div>
					)}
					<MainPicture pictureUrl={pictures[imageIndex]?.src} onClick={onTap} />
					{!!isCollageSubscribed && <VipRibbon />}
					<div className="statusBarCard">
						<UserStatus isOnline={isOnline} />
						{(!!displayUser?.distance || displayUser?.distance == "0") && (
							<UserDistance distance={displayUser.distance} />
						)}
					</div>
					<BasicDetails profile={displayUser} onTap={onTap} />
					<ShareButton profile={displayUser} />
					<ImageTapIndicator
						length={pictures.length}
						selected={imageIndex}
						onTapDot={(index) => onTapDot(index)}
					/>
				</div>
				<div className="userProfileDetails">
					<UserAboutAndTags profile={displayUser} />
					<UserYoutubeLink profile={displayUser} />
					<UserProfileDetails profile={displayUser} />
					<ImagesGallery pictures={pictures} title={i18next.t("Pictures")} />

					{!!displayUser && displayUser?.uid !== currentUser?.uid && (
						<ShareContacts colleagueId={displayUser?.uid} colleague={currentUserProfile} />
					)}

					{!!currentUser?.uid && displayUser?.uid !== currentUser?.uid && (
						<UserBlocks profile={displayUser} />
					)}
				</div>
			</SimpleBar>
			{isMobile() &&
				!!displayUser?.uid &&
				(!currentUser?.uid || (!!currentUser?.uid && currentUser?.uid !== displayUser?.uid)) && (
					<FooterBtnsActions user={displayUser} />
				)}
		</Modal>
	);
}
