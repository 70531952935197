import i18next from "i18next";
import { CardImg } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";

import { getAgeByDob } from "helpers/date";
import currentLanguage from "helpers/currentLanguage";
import profileFields from "consts/profileFields";
import usePopups from "hooks/usePopups";
import isMobile from "helpers/isMobile";
import { TransformImg } from "helpers/cloudinary";
import { Face, Fill } from "consts/cloudinaryOptions";
import { isUserOnline } from "redux/presence/selectors";
import UserDistance from "components/UserDistance";
import UserStatus from "components/UserStatus";
import VipRibbon from "components/VipRibbon1";
import Subscription from "consts/subscription";
import { viewUser } from "redux/search/actions";
import placeholderMan from "assets/images/emptyImages/placeholderMan.png";
import placeholderWoman from "assets/images/emptyImages/placeholderWoman.png";
import privatePicture from "assets/images/emptyImages/private.jpg";
import { getUserProfile } from "redux/profile/selectors";
import birthday from "assets/images/birthday/happyBirthDay.png";
import getIfUserBirtdayInThisWeek from "helpers/getIfUserBirtdayInThisWeek";
import { getIsPlatformAndroid, getIsPlatformIOS, getPlatform } from "redux/init/selectors";
import contactsService from "services/contactsService";
import useHelper from "hooks/useHelper";

export default ({
	user,
	mouseIsOverCard = false,
	deletable = false,
	updateSelectedUser,
	selectedUsers,
	addLikedMeTime = false,
	addLikesTime = false,
	addViewedMeTime = false,
	addViewsTime = false,
}) => {
	const { showUserCardPopup, showUserProfileSideBar } = usePopups();

	const dispatch = useDispatch();
	const currentUser = useSelector(getUserProfile);
	const showCheckbox = deletable && (mouseIsOverCard || selectedUsers.size > 0);
	const isOnline = useSelector(isUserOnline(user.uid));

	const uri = user.pictures?.[user.mainPictureName]?.url || "";
	const path = uri
		? TransformImg(uri, undefined, null, Fill.Scale, Face.None, "", false)
		: user.isPicturesPrivate
		? privatePicture
		: user.gender
		? placeholderWoman
		: placeholderMan;
	const long_city = user[profileFields.Cites[currentLanguage()]];
	const city = long_city?.split(",")[0];

	const imageProfile = currentUser.pictures?.[currentUser.mainPictureName]?.url || "";
	const isPlatformAndroid = useSelector(getIsPlatformAndroid);
	const isPlatformIOS = useSelector(getIsPlatformIOS);
	const helper = useHelper();

	const showCard = () => {
		const isAllow = helper.showUserDetails();
		if (isAllow) {
			dispatch(viewUser(user.uid));
			!isMobile() ? showUserCardPopup(user) : showUserProfileSideBar(user);
		}
	};

	const getTime = (time) => {
		const utcTime = moment.utc(time, "YYYYMMDDhhmmss");
		return moment().diff(moment(utcTime, "YYYYMMDDhhmmss"), "days") > 1
			? moment(utcTime, "YYYYMMDDhhmmss").format("DD.MM.YYYY")
			: moment(utcTime, "YYYYMMDDhhmmss").fromNow();
	};
	const userDob = (user.dob && getAgeByDob(user.dob)) || user.age;
	return (
		<>
			{showCheckbox && (
				<div className="checkDelete">
					<input
						type="checkbox"
						checked={selectedUsers.has(user.uid)}
						onChange={(e) => updateSelectedUser(e.target.checked, user.uid)}
					/>
				</div>
			)}
			{(user.isSubscribed ||
				user.subscription === Subscription.PAID ||
				user.subscription === Subscription.FREE) &&
				false && <VipRibbon />}
			<div className="statusBarCard">
				{!!getIfUserBirtdayInThisWeek(user?.dob) && (
					<div className="birthDayStick">
						<img src={birthday} alt="birthday" />
					</div>
				)}
				<div className="wrapStatusDis">
					<UserStatus isOnline={isOnline} />
					{(!!user?.distance || user?.distance == "0") && <UserDistance distance={user.distance} />}
				</div>
				{((addLikedMeTime && user.liked_me_timeStamp) ||
					(addLikesTime && user.likes_timeStamp) ||
					(addViewedMeTime && user.viewed_me_timeStamp) ||
					(addViewsTime && user.views_timeStamp)) && (
					<div className="time">
						<span>צפייה חדשה</span>
						{addLikedMeTime && user.liked_me_timeStamp && getTime(user.liked_me_timeStamp)}
						{addLikesTime && user.likes_timeStamp && getTime(user.likes_timeStamp)}
						{addViewedMeTime && user.viewed_me_timeStamp && getTime(user.viewed_me_timeStamp)}
						{addViewsTime && user.views_timeStamp && getTime(user.views_timeStamp)}
					</div>
				)}
			</div>

			<div className="cardImage" onClick={() => showCard()}>
				<CardImg alt="Card image cap" src={path} top width="100%" />
			</div>
			<div className="wrapShortTxt">
				{!isMobile() ? (
					<h4>{user.name} </h4>
				) : (
					<>
						<h4>
							<span>{user.name}</span>
							<span>{userDob} </span>
						</h4>
					</>
				)}
				<div className="wrapRow">
					<p>{i18next.t(`MaritalStatus_${user.gender}.${user.maritalStatus}`)}</p>
					<p className="bullet"></p>
					<p>{i18next.t(`Stance_${user.gender}.${user.stance}`)}</p>
					{!isMobile() ? (
						<>
							{" "}
							{userDob && <p className="bullet"></p>}
							<p>{userDob || user.age}</p>{" "}
						</>
					) : (
						<></>
					)}
					{city && <p className="bullet"></p>}
					<p className="city">{city}</p>
				</div>
			</div>
		</>
	);
};
