import { useSelector } from "react-redux";
import moment from "moment";
import i18next from "i18next";

import { getLoggedInUserUID } from "redux/auth/selectors";
import { getUserPublicProfile } from "redux/publicProfiles/selectors";
import Day from "../DayDivider";
import { timeFormat } from "consts/date";
import ChatAvatar from "../ChatAvatar";
import AudioPlayer from "../AudioPlayer";
import usePopups from "hooks/usePopups";
import PromotionMessage from "components/PromotionMessage";
import { UncontrolledTooltip } from "reactstrap";
import InfoIcon from "./InfoIcon";
import { useEffect } from "react";
import { getUserProfile } from "redux/profile/selectors";

export default ({ index, currentMessage, chatMessages, showIcon }) => {
	const active_userId = useSelector((state) => state.chat.active_userId);
	const loggedInUserUID = useSelector(getLoggedInUserUID);
	const activeUserProfile = useSelector(getUserPublicProfile(active_userId)) || {};

	const isSender = currentMessage.uid === loggedInUserUID;

	const previousMessage = chatMessages[index - 1];
	const nextMessage = chatMessages[index + 1];

	const addUserNameAfterMessage =
		!(nextMessage && currentMessage.uid === nextMessage.uid) && !isSender;

	return (
		<li
			key={currentMessage.id}
			className={`${isSender ? "right" : ""} ${currentMessage.isTemplate ? "template" : ""}  ${
				currentMessage?.classImg ? `${currentMessage?.classImg}` : ""
			}`}>
			<Day currentMessage={currentMessage} previousMessage={previousMessage} />
			<div className="conversation-list" style={{ width: currentMessage?.audio && "100%" }}>
				<ChatAvatar currentMessage={currentMessage} nextMessage={nextMessage} />
				<div className="user-chat-content" style={{ width: currentMessage?.audio && "100%" }}>
					<MessageContent
						currentMessage={currentMessage}
						isSender={isSender}
						name={activeUserProfile.name}
						showIcon={showIcon}
					/>
					{addUserNameAfterMessage && false && (
						<div className="conversation-name">{activeUserProfile.name}</div>
					)}
				</div>
			</div>
		</li>
	);
};

const IsMessageRead = ({ currentMessage }) => {
	return (
		<div className={`is-read-message${currentMessage?.read ? " message-was-read " : ""}`}>
			{!!currentMessage.sent && <div>✓</div>}
			{!!currentMessage.sent && <div>✓</div>}
			{!currentMessage.sent && <div>🕓</div>}
		</div>
	);
};

function generateMaskedText(input) {
	if (true) return "";

	const words = input.split(" ");
	if (words[words.length - 1] == "") {
		words.pop();
	}
	let maskedText = "";
	let countShowChars = 1;
	for (let i = 0; i < words.length; i++) {
		const word = words[i];
		if (words.length === 1 && word.length <= countShowChars * 2) {
			maskedText = word;
		} else if (i === 0 && i === words.length - 1) {
			// if only one word
			maskedText +=
				word.slice(0, countShowChars) +
				"*".repeat(Math.abs(word.length - countShowChars * 2)) +
				word.slice(Math.abs(word.length - countShowChars), word.length);
		} else if (i === 0) {
			maskedText += word.slice(0, countShowChars) + "*".repeat(word.length - countShowChars);
		} else if (i === words.length - 1) {
			maskedText +=
				"*".repeat(Math.abs(word.length - countShowChars)) +
				word.slice(Math.abs(word.length - countShowChars), word.length);
		} else {
			maskedText += "*".repeat(word.length);
		}
		maskedText += " ";
	}

	return maskedText;
}
const MessageContent = ({ currentMessage, isSender, name, showIcon = false }) => {
	const currentUser = useSelector(getUserProfile) || {};
	const canRead = useSelector((state) => !!state.chat?.canRead) || false;

	const renderMessageContent = () => {
		//currentMessage = { ...currentMessage, isMassletter: true, privateBlur: false };

		if (currentMessage.isPromotion) {
			return <PromotionMessage message={currentMessage.text} name={name} />;
		}
		if (!canRead && (currentMessage.generalBlur || currentMessage.privateBlur)) {
			if (currentMessage.generalBlur) {
				return (
					<BlurMessage
						type="general"
						infoText={i18next.t("Chat Message.generalInfo")}
						text={generateMaskedText(currentMessage.text)}
						showIcon={showIcon}
						desc={i18next.t(`Chat Message.General Message_${currentUser?.gender}`, { name })}
					/>
				);
			}
			if (currentMessage.privateBlur) {
				return (
					<BlurMessage
						type="private"
						infoText={i18next.t("Chat Message.privateInfo")}
						text={generateMaskedText(currentMessage.text)}
						showIcon={showIcon}
						desc={i18next.t(`Chat Message.Private Message_${currentUser?.gender}`, { name })}
					/>
				);
			}
		}
		if (currentMessage.audio) return <AudioPlayer currentMessage={currentMessage} />;

		const text = currentMessage.text;

		let htmlString = `${text}${
			currentMessage?.isMassletter
				? '<br/><span class="massletter-text">' +
				  i18next.t("Chat Message.Massletter Text") +
				  "</span>"
				: ""
		}`;

		if (showIcon) {
			return (
				<>
					<span className="mb-0" dangerouslySetInnerHTML={{ __html: htmlString }} />
					<InfoIcon
						type={"isMassletter"}
						text={i18next.t("Chat Message.massletterInfo")}></InfoIcon>
					<span class="massletter-text">)</span>
				</>
			);
		}
		return (
			<>
				<span
					className="mb-0"
					style={{ whiteSpace: "pre-wrap" }}
					dangerouslySetInnerHTML={{ __html: `${htmlString}` }}
				/>
				{currentMessage?.isMassletter && <span class="massletter-text">)</span>}
			</>
		);
	};
	return (
		<div className="ctext-wrap">
			<div className="ctext-wrap-content" style={{ width: currentMessage?.audio && "100%" }}>
				{renderMessageContent()}
				<p className="chat-time mb-0">
					{isSender && <IsMessageRead currentMessage={currentMessage} />}
					&nbsp;&nbsp;
					<span className="align-middle">
						{moment(currentMessage.timeStamp).locale("en").format(timeFormat)}
					</span>
				</p>
			</div>
		</div>
	);
};

const BlurMessage = ({ text, desc, showIcon = false, type, infoText }) => {
	const { showBlurMessagePopup } = usePopups();

	return (
		<>
			{/* <p>{text}</p> */}
			<span className="p-link" onClick={showBlurMessagePopup}>
				{desc}
			</span>
			{!!showIcon && type && <InfoIcon key={type} type={type} text={infoText}></InfoIcon>}
		</>
	);
};
